import React, { useEffect, useId, useRef, useState } from "react";
import { Calendar } from "lucide-react";
import { format, isValid, parse } from "date-fns";
import { DayPicker } from "react-day-picker";
import { Input } from "../ui/input";
import { cn } from "../../lib/utils";

export interface InputDatePickerProps {
  date?: Date;
  placeholder?: string;
  name?: string;
  onChange?: (data: Date) => void
  error?: boolean
}

const InputDatePicker: React.FC<InputDatePickerProps> = ({
  date,
  placeholder,
  name,
  onChange,
  error
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const dialogId = useId();
  const headerId = useId();

  // Hold the month in state to control the calendar when the input changes
  const [month, setMonth] = useState(new Date());

  const initialDate = date ? date : undefined;

  // Hold the selected date in state
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialDate,
  );

  // Hold the input value in state
  const [inputValue, setInputValue] = useState<string | Date>(
    initialDate ? format(initialDate, "dd/MM/yyyy") : "",
  );

  // Hold the dialog visibility in state
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Function to toggle the dialog visibility
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  // Hook to handle the body scroll behavior and focus trapping
  useEffect(() => {
    const handleBodyScroll = (isOpen: boolean) => {
      document.body.style.overflow = isOpen ? "hidden" : "";
    };

    if (!dialogRef.current) return;

    if (isDialogOpen) {
      handleBodyScroll(true);
      dialogRef.current.showModal();
    } else {
      handleBodyScroll(false);
      dialogRef.current.close();
    }

    return () => {
      handleBodyScroll(false);
    };
  }, [isDialogOpen]);

  const handleDayPickerSelect = (date: Date) => {
    if (!date) {
      setInputValue("");
      setSelectedDate(undefined);
    } else {
      setSelectedDate(date);
      setInputValue(format(date, "dd/MM/yyyy"));
      if (onChange) {
        onChange(date)
      }
    }
    setIsDialogOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value); // keep the input value in sync

    const parsedDate = parse(e.target.value, "dd/MM/yyyy", new Date());
    if (isValid(parsedDate)) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate);
    } else {
      setSelectedDate(undefined);
    }

  };

  const handleDialogClick = (e: React.MouseEvent<HTMLDialogElement>) => {
    const dialogDimensions = dialogRef.current?.getBoundingClientRect();
    if (dialogDimensions) {
      const clickedInDialog =
        e.clientX >= dialogDimensions.left &&
        e.clientX <= dialogDimensions.right &&
        e.clientY >= dialogDimensions.top &&
        e.clientY <= dialogDimensions.bottom;

      if (!clickedInDialog) {
        setIsDialogOpen(false);
      }
    }
  };

  return (
    <div>
      <div className="relative w-full">
        <Input
          id="date-input"
          type="text"
          value={inputValue as string}
          className={`relative bg-neutral-50 ${error ? "border-red-500" : "border-neutral-200"}`}
          name={name ? name : "date"}
          placeholder={placeholder ? placeholder : "Date"}
          onChange={handleInputChange}
          onClick={toggleDialog}
        />{" "}
        <Calendar
          size={18}
          onClick={toggleDialog}
          aria-controls="dialog"
          aria-haspopup="dialog"
          aria-expanded={isDialogOpen}
          aria-label="Open calendar to choose booking date"
          className="absolute text-neutral-400 right-2 top-1/2 -translate-y-1/2 cursor-pointer"
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      {/* @ts-ignore */}
      <dialog
        ref={dialogRef}
        id={dialogId}
        aria-modal
        aria-labelledby={headerId}
        onClose={() => setIsDialogOpen(false)}
        onClick={handleDialogClick}
        className="p-3 rounded-lg backdrop:bg-black/50"
      >
        <DayPicker
          month={month}
          onMonthChange={setMonth}
          autoFocus
          mode="single"
          required={true}
          selected={selectedDate}
          onSelect={handleDayPickerSelect}
          className="p-3 rounded-md gap-2 bg-white"
          classNames={{
            months: "",
            month: "space-y-4",
            caption: "flex justify-center pt-1 relative items-center",
            caption_label: "text-sm font-medium",
            nav: "space-x-1 flex items-center",
            nav_button: cn(
              "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
            ),
            nav_button_previous: "absolute left-1 border p-2",
            nav_button_next: "absolute right-1",
            table: "w-full border-collapse space-y-1",
            head_row: "flex",
            head_cell:
              "text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]",
            row: "flex w-full mt-2",
            cell: cn(
              "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
            ),
            day: "p-2 hover:bg-neutral-100 rounded-md",
            day_range_start: "day-range-start",
            day_range_end: "day-range-end",
            day_selected:
              "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
            day_today: "bg-accent text-accent-foreground",
            day_outside:
              "day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
            day_disabled: "text-muted-foreground opacity-50",
            day_range_middle:
              "aria-selected:bg-accent aria-selected:text-accent-foreground",
            day_hidden: "invisible",
          }}
        />
      </dialog>
    </div>
  );
}

export default InputDatePicker;