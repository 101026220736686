import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import KhataAccountDetailsCard from "../components/app/cards/khata-account-details-card";
import KhataPaymentStatusCard from "../components/app/cards/khata-payment-status-card";
import clsx from "clsx";
import { Undo2Icon } from "lucide-react";
import { KhataItemDataType, KhataPaymentStatus, KhataTransactionDataType } from "../lib/DataType/KhataDataType";
import { ErrorHandler } from "../lib/Utils/Common";
import KhataAmountDetailsCard from "../components/app/cards/KhataAmountDetailsCard";
import { GetKataAmountListService, GetKataListService } from "../lib/Service/KhataService";
import KhataAddAccountModal from "../components/app/modals/KhataAddAccountModal";
import KhataHistoryCard from "../components/app/cards/KhataHistoryCard";
import KhataAmountHistoryModal from "../components/app/modals/KhataAmountHistoryModal";

type KhataAmounModalDataType = {
  isOpen: boolean,
  data: KhataTransactionDataType | null
  type: number
  isEdit?: false
} | {
  isOpen: boolean,
  data: KhataTransactionDataType | null
  type: number
  isEdit: true
  index: number
}


const KhataPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showKhataAmounModal, setShowKhataAmountModal] = useState<KhataAmounModalDataType>({
    data: null,
    isOpen: false,
    type: 1,
    isEdit: false
  });

  const [showAddAccountModal, setShowAddAccountModal] = useState<boolean>(false);

  // for small screens only
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [showAccountDetails, setShowAccountDetails] = useState<boolean>(false);


  const [giveAmt, setGiveAmt] = useState<number>(0)
  const [getAmt, setGetAmt] = useState<number>(0)

  const [paymentList, setPaymentList] = useState<KhataItemDataType[]>([])

  const [accountDetails, setAccountDetails] = useState<KhataItemDataType | null>(null)
  const [singlePaymentList, setSinglePaymentList] = useState<KhataTransactionDataType[]>([])



  useEffect(() => {
    function handleResize() {
      const isSmall = window.innerWidth < 768;
      setIsSmallScreen(isSmall);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    GetKataListService()
      .then((res) => {
        if (res.status) {
          const data = res.data
          setGiveAmt(data.give_amt)
          setGetAmt(data.get_amt)
          setPaymentList(data.data)
        }
      }).catch(ErrorHandler)
  }, [])

  const clickAccount = (item: KhataItemDataType) => {
    setAccountDetails(item)
    setSinglePaymentList([])

    GetKataAmountListService(item.id)
      .then((res) => {
        if (res.status) {
          const data = res.data
          setAccountDetails(data.khata_account)
          setSinglePaymentList(data.transaction.data)
        }
      })
      .catch(ErrorHandler)
      .finally(() => setShowAccountDetails(true))
  }


  const toggleAddAccountModal = () => {
    setShowAddAccountModal((prev) => !prev);
  };

  const toggleAccountDetails = () => {
    setShowAccountDetails((prev) => !prev);
  };

  const onSubmitPayment = (item: KhataTransactionDataType) => {
    console.log('item', item)
    if (showKhataAmounModal.isEdit) {
      if (accountDetails) {
        clickAccount(accountDetails)
      }
    } else {
      setSinglePaymentList(singlePaymentList => singlePaymentList.concat(item))
    }
    setShowKhataAmountModal({ data: null, type: 1, isOpen: false })
  }

  return (
    <div className="w-full px-5 grid md:grid-cols-2 gap-10 sm:gap-2">
      <div
        className={clsx(
          `space-y-2 min-h-[70vh] mb-5 sm:mb-0 flex flex-col md:border-r sm:pr-4`,
          {
            hidden: showAccountDetails && isSmallScreen,
          },
        )}
      >
        {/* get give card */}
        <div className="flex justify-between gap-5 w-full">
          <div className="p-3 border flex flex-col gap-2 justify-center items-center rounded-lg w-full max-w-[15rem] text-sm sm:text-base">
            <p className="text-center text-green-600 font-bold">₹ {getAmt}</p>
            <p className="text-neutral-400">You will get</p>
          </div>
          <div className="flex justify-end w-full">
            <div className="p-3 border flex flex-col text-sm sm:text-base gap-2 justify-center items-center rounded-lg w-full max-w-[15rem]">
              <p className="text-center text-cardinal-600 font-bold">₹ {giveAmt}</p>
              <p className="text-neutral-400">You will give</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Input
            type="text"
            value={searchParams.get("search") || ""}
            onChange={(e) => setSearchParams({ search: e.target.value })}
            className="w-full text-sm sm:text-base shadow-none border-neutral-200 rounded-lg"
            placeholder="Search"
          />
        </div>
        <div>
          <h3 className="font-bold sm:text-lg my-4">Amount</h3>
          <div className="flex flex-col space-y-3">
            {paymentList.map((payment, index) => (
              <div
                key={index}
                onClick={() => clickAccount(payment)}
                className="cursor-pointer"
              >
                <KhataAmountDetailsCard
                  amount={payment.total_amount}
                  bussinessName={payment.company_name}
                  paymentStatus={payment.amount_type === 1 ? KhataPaymentStatus.ToPay : KhataPaymentStatus.ToAdvance}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 flex justify-center items-end h-full">
          <Button
            className="w-full max-w-xs bg-blue-500 hover:bg-blue-500 text-sm ms:text-base"
            onClick={toggleAddAccountModal}
          >
            Add New Account
          </Button>
        </div>
      </div>


      {
        showAccountDetails &&
        <div
          className={clsx(
            "md:flex space-y-2 md:space-y-0 md:gap-3  min-h-[70vh] sm:pl-2 md:flex-col",
            {
              hidden: !showAccountDetails,
            },
          )}
        >
          <div
            className={clsx("md:hidden ", {
              flex: showAccountDetails,
            })}
          >
            <Button
              variant="outline"
              className="rounded-full mb-3 items-center font-normal py-1"
              onClick={toggleAccountDetails}
            >
              <Undo2Icon size={12} />
              Back
            </Button>
          </div>
          {
            accountDetails !== null &&
            <>
              <div className="w-full">
                <KhataAccountDetailsCard
                  bussinessName={accountDetails.company_name}
                  mobileNumber={accountDetails.mobile_no}
                  name={accountDetails.contact_name}
                />
              </div>
              <div className="space-y-2">
                <KhataPaymentStatusCard
                  amount={accountDetails?.total_amount}
                  paymentStatus={accountDetails.amount_type === 1 ? KhataPaymentStatus.ToPay : KhataPaymentStatus.ToAdvance}
                />
              </div>
            </>
          }
          <div>
            <h3 className="font-bold sm:text-lg my-4">History</h3>
            <div className="space-y-2">
              {
                singlePaymentList.map((item, index) => {
                  if (item) {
                    return (
                      <KhataHistoryCard
                        amount={item?.amount || 0}
                        date={new Date(item.date)}
                        orderId={item.order_id}
                        paymentStatus={item.amount_type === 1 ? KhataPaymentStatus.ToPay : KhataPaymentStatus.ToAdvance}
                        reason={item.reason}
                        id={item.khata_id}
                        onEdit={() => setShowKhataAmountModal({ data: item, type: item.amount_type, isOpen: true, isEdit: true, index })}
                        key={index} />
                    )
                  } 
                  return null

                })
              }
            </div>
            <div className="flex-1 flex justify-center my-5 items-center h-full w-full">
              <div className="flex gap-2 justify-between w-full">
                <Button
                  className="w-full max-w-xs bg-cardinal-600 hover:bg-cardinal-600 text-sm ms:text-base"
                  onClick={() => setShowKhataAmountModal({ data: null, type: 1, isOpen: true })}
                >
                  TO PAY
                </Button>
                <Button
                  className="w-full max-w-xs bg-green-600 hover:bg-green-600 text-sm ms:text-base"
                  onClick={() => setShowKhataAmountModal({ data: null, type: 2, isOpen: true })}
                >
                  RECIEVE
                </Button>
              </div>
            </div>
          </div>
        </div>
      }





      <KhataAddAccountModal
        isOpen={showAddAccountModal}
        onClose={toggleAddAccountModal}
        onSubmit={data => {
          setPaymentList(paymentList => paymentList.concat(data))
          toggleAddAccountModal()
        }}
      />
      {
        showKhataAmounModal.isOpen && accountDetails ?
          <KhataAmountHistoryModal
            isOpen={showKhataAmounModal.isOpen}
            onClose={() => setShowKhataAmountModal({ data: null, type: 1, isOpen: false })}
            onSubmit={onSubmitPayment}
            id={accountDetails?.id}
            isEdit={showKhataAmounModal.isEdit}
            type={showKhataAmounModal.type}
            item={showKhataAmounModal.data}
          /> : null
      }

    </div>
  );
}
export default KhataPage;