
import { ResDataType } from "../DataType/ApiCommon"
import { LoginRequestDataType, ProfileDataType } from "../DataType/AuthDataType"
import HttpClient from "../Utils/HttpClient"

export const SendOtpServive = (contact_no: string): Promise<ResDataType> => {
    return HttpClient.post(`/auth/login`, { contact_no })
}

export const LoginService = (request: LoginRequestDataType): Promise<ResDataType<ProfileDataType>> => {
    return HttpClient.post("/auth/login-otp", request);
}