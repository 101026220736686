import { KhataPaymentStatus } from "../../../lib/DataType/KhataDataType";


export interface KhataPaymentDetailsProps {
  paymentStatus: KhataPaymentStatus;
  amount: number;
}

export default function KhataPaymentStatusCard({
  ...props
}: KhataPaymentDetailsProps) {
  return (
    <div className="p-2 border rounded-sm hover:bg-neutral-50 transition-transform duration-300 flex justify-between items-center text-xs sm:text-xs">
      <p>{props.paymentStatus}</p>
      {props.paymentStatus === KhataPaymentStatus.ToPay ? (
        <p className="text-cardinal-600 font-bold">- ₹{props.amount}</p>
      ) : (
        <p className="text-green-600 font-bold">₹{props.amount}</p>
      )}
    </div>
  );
}
