import SearchLoadForm from "../components/app/forms/SearchLoadForm";
import BiltyHomeCard from "../components/app/cards/bilty-home-card";
import RecentLoadCard from "../components/app/cards/RecentLoadCard";
import { useEffect, useState } from "react";
import { LoadDataType } from "../lib/DataType/LoadDataType";
import { GetLoadDataType } from "../lib/Service/LoadService";
import { ErrorHandler } from "../lib/Utils/Common";

export enum LoadStatus {
  Pending = "Pending",
  InProcess = "In Process",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

enum BiltyStatus {
  Pending = "Pending",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
}

export interface BiltyCardProps {
  lrNumber: string;
  orders: number;
  date: Date;
  vehicleNumber: string;
  from: string;
  status: BiltyStatus;
  to: string;
  fromLocation: string;
  toLocation: string;
}

const Homepage = () => {
  const biltyData: BiltyCardProps[] = [];
  const [loadList, setLoadList] = useState<LoadDataType[]>([])

  useEffect(() => {
    GetLoadDataType("pagination=3")
      .then((res) => {
        if (res.status) {
          setLoadList(res.data.data)
        }
      })
      .catch(ErrorHandler)
  }, [])

  return (
    <div className="w-full space-y-3 mb-10">
      <div className="">
        <h3 className="font-bold text-lg px-5 sm:text-2xl">Search Loads</h3>
        <div className="grid lg:grid-cols-2 gap-5">
          <div className="space-y-4 px-5 h-full w-full">
            <SearchLoadForm />
          </div>
          <div className="w-full h-full px-5 flex justify-center items-center">
            <img
              src="/home/home-delivery.svg"
              alt="home delivery"
              className=" h-full"
            />
          </div>
        </div>
      </div>

      {loadList.length > 0 || biltyData.length > 0 ? (
        <div className="space-y-3">
          {
            loadList.length > 0 &&
            <div className="space-y-4 px-5">
              <h3 className="font-bold text-lg sm:text-2xl">Recent Loads</h3>
              <div className="grid gap-5 lg:grid-cols-2 xl:grid-cols-3 ">
                {loadList.map((load, i) => (
                  <RecentLoadCard
                    from={load.pickup_city}
                    itemType={load.item_type}
                    orderId={load.order_ref_no}
                    pickUp={new Date(load.pickup_date)}
                    rate={load.proposed_price}
                    status={BiltyStatus.Delivered}
                    to={load.delivery_city}
                    vehicleCapacity={load.capacity}
                    vehicleType={load.vehicle_type}
                    key={i}
                  />
                ))}
              </div>
            </div>
          }

          {
            biltyData.length > 0 &&
            <div className="space-y-4 px-5">
              <h3 className="font-bold text-lg sm:text-2xl">Bilty</h3>
              <div className="grid gap-5 md:grid-cols-2">
                {biltyData.map((bilty) => (
                  <BiltyHomeCard {...bilty} key={bilty.lrNumber} />
                ))}
              </div>
            </div>
          }
        </div>
      ) : (
        <div className="p-3 flex justify-center items-center">
          <img
            src="/home/creditcard.svg"
            alt="credit"
            className="h-48 sm:h-56 my-10"
          />
        </div>
      )}
    </div>
  );
}

export default Homepage;