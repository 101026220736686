import { LiaTruckSolid } from "react-icons/lia";
import { clsx } from "clsx";
import { Check, ThumbsDown, MessageSquareMore } from "lucide-react";
import { Link } from "react-router-dom";

interface RecentLoadCardProps {
  orderId: string;
  status: string; // change it to enum acc to backend
  from: string;
  to: string;
  rate: number;
  vehicleType: string;
  vehicleCapacity: string | null;
  itemType: string;
  pickUp: Date;
}

export default function MarketPlaceOrderCard({
  ...props
}: RecentLoadCardProps) {
  return (
    <Link
      to="/marketplace/order"
      className="relative rounded-lg border flex flex-col gap-4 shadow-sm w-full"
    >
      <div className="p-4 w-full flex-1">
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center gap-2">
            <div className="p-2 bg-neutral-200/30 rounded-md">
              <LiaTruckSolid size={28} className="text-neutral-400" />
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-neutral-400 font-normal text-xs">Order ID</p>
              <p className="text-neutral-700 font-medium text-xs sm:text-sm">
                {props.orderId}
              </p>
            </div>
          </div>
          <div>
            <p className="text-cardinal-700 font-bold text-sm sm:text-base">
              ₹ {props.rate}
            </p>
          </div>
        </div>
        <div className="flex-1 w-full mt-4">
          <div className="w-full grid grid-cols-3 gap-4">
            <div className="flex flex-col justify-between gap-2 h-full">
              <div>
                <p className="text-neutral-400 font-normal text-xs">From</p>
                <p className="font-semibold text-xs sm:text-sm">{props.from}</p>
              </div>
              <div>
                <p className="text-neutral-400 font-normal text-xs">To</p>
                <p className="font-semibold text-xs sm:text-sm">{props.to}</p>
              </div>
            </div>
            <div className="flex justify-center items-start">
              <div className="flex flex-col justify-between h-full w-full">
                <div>
                  <p className="text-neutral-400 font-normal text-xs">
                    Vehicle Type
                  </p>
                  <p className="font-semibold text-xs sm:text-sm">
                    {props.vehicleType}
                  </p>
                </div>
                <div>
                  <p className="text-neutral-400 font-normal text-xs">
                    Capacity
                  </p>
                  <p className="font-semibold text-xs sm:text-sm">
                    {props.vehicleCapacity}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between gap-2 h-full">
              <div>
                <p className="text-neutral-400 text-right font-normal text-xs">
                  Type
                </p>
                <p className="font-semibold text-right text-xs sm:text-sm">
                  {props.itemType}
                </p>
              </div>
              <div>
                <p className="text-neutral-400 text-right font-normal text-xs">
                  Pickup
                </p>
                <p className="font-semibold text-xs sm:text-sm text-right">
                  {props.pickUp.getDate()}{" "}
                  {props.pickUp.toLocaleString("default", { month: "short" })}{" "}
                  {props.pickUp.getFullYear()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <p
          className={clsx(
            "absolute right-4 -top-[11px] px-3 py-0.5 text-white text-xs sm:text-sm rounded-full",
            {
              "bg-yellow-500": props.status === "Pending",
              "bg-violet-600": props.status === "In Process",
              "bg-green-600": props.status === "Completed",
              "bg-red-600": props.status === "Cancelled",
            },
          )}
        >
          {props.status}
        </p>
      </div>
      <div className="border-t p-2 flex justify-around gap-3 text-sm ">
        <div className="flex items-center cursor-pointer">
          <Check size={16} className="text-green-600 mr-1" />
          <p className="text-green-600 mr-1 font-semibold text-sm sm:text-base">
            Accept
          </p>
        </div>
        <div className="border" />
        <div className="flex items-center cursor-pointer">
          <ThumbsDown size={13} className="text-cardinal-600 mr-1" />
          <p className="text-cardinal-600 font-semibold text-sm sm:text-base">
            Reject
          </p>
        </div>
        <div className="border" />
        <div className="flex items-center cursor-pointer">
          <MessageSquareMore size={16} className="text-yellow-500 mr-1" />
          <p className="text-yellow-500 font-semibold text-sm sm:text-base">
            Chat
          </p>
        </div>
      </div>
    </Link>
  );
}
