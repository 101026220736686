import { useSearchParams } from "react-router-dom";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Search } from "lucide-react";
import MarketPlaceOrderCard from "../components/app/cards/marketplace-order-card";
import { useEffect, useState } from "react";
import { GetLoadDataType } from "../lib/Service/LoadService";
import { ErrorHandler } from "../lib/Utils/Common";
import { LoadDataType } from "../lib/DataType/LoadDataType";

const MarketPlace = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [recentLoadData, setRecentLoadData] = useState<LoadDataType[]>([]);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const obj = {
      orderId: formData.get("orderId") as string,
    };
    console.log(obj);
  };


  useEffect(() => {
    GetLoadDataType("")
      .then((res) => {
        if (res.status) {
          setRecentLoadData(res.data.data)
        }
      })
      .catch(ErrorHandler)
  }, [])

  return (
    <div className="w-full px-5 mb-10">
      <div className="flex justify-center items-center my-10 w-full">
        <form
          onClick={handleSubmit}
          className="w-full max-w-xl flex justify-center items-center"
        >
          <div className="relative w-full">
            <Label htmlFor="search">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-400 h-4 w-4" />
            </Label>
            <Input
              placeholder="Search"
              id="search"
              name="orderId"
              value={searchParams.get("orderId") || ""}
              onChange={(e) => setSearchParams({ orderId: e.target.value })}
              className="pl-9 pr-4 h-10 shadow-none border border-neutral-200 bg-neutral-50 rounded-md w-full"
            />
          </div>
        </form>
      </div>
      <div className="grid sm:grid-cols-2 gap-5 justify-center w-full">
        {recentLoadData.map((load, i) => (
          <MarketPlaceOrderCard
            from={load.pickup_city}
            itemType={load.item_type}
            orderId={load.order_ref_no}
            pickUp={new Date(load.pickup_date)}
            rate={load.proposed_price}
            status={"Pending"}
            to={load.delivery_city}
            vehicleCapacity={load.capacity}
            vehicleType={load.vehicle_type}
            key={i}
          />
        ))}
      </div>
    </div>
  );
}


export default MarketPlace