import { ResDataType } from "../DataType/ApiCommon";
import { CreateKhataAccountDataType, GetKhataAmountDataType, GetKhataDataType, KhataAmountRequestDataType, KhataItemDataType, KhataTransactionDataType } from "../DataType/KhataDataType";
import HttpClient from "../Utils/HttpClient";

export const GetKataListService = (): Promise<ResDataType<GetKhataDataType>> => {
    return HttpClient.get("/khata/list")
}

export const GetKataAmountListService = (id: string | number): Promise<ResDataType<GetKhataAmountDataType>> => {
    return HttpClient.get(`/khata/${id}/get-khata-amount-list`)
}

export const AddNewKataService = (request: CreateKhataAccountDataType): Promise<ResDataType<KhataItemDataType>> => {
    return HttpClient.post("/khata/add-new", request)
}

export const AddKataAmountService = (id: string | number, request: KhataAmountRequestDataType): Promise<ResDataType<KhataTransactionDataType>> => {
    return HttpClient.post(`/khata/${id}/add-amount`, request)
}

export const UpdateKataAmountService = (id: string | number, request: KhataAmountRequestDataType): Promise<ResDataType<KhataTransactionDataType>> => {
    return HttpClient.patch(`/khata/${id}/update-amount`, request)
}
